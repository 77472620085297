import { Grid } from "@mui/material";
import "react-dropzone-uploader/dist/styles.css";
import {
  getParams,
  navigate,
  guardRoute,
  sendGetRequest,
  sendPostRequest,
  sendPatchRequest,
} from "../utils/utils";
import EditPage from "./component/edit-page";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";
import EasyTextEdit from "./component/text-edit";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";

const _ref = {
  uuid: "",
  storeRef: "",
  description: "",
  duration: null,
  playstore: true
};


const PackageEdit = () => {
  guardRoute(false, true);
  const { uuid } = getParams();
  var [pakeji, setPakeji] = useState(_ref);

  const [status, setStatus] = useState({
    error: false,
    loading: false,
    message: null,
  });
  useEffect(() => {
     sendGetRequest(`packages?uuid=${uuid}`,
       (loading) => setStatus({ ...{ loading: loading } }), (data) => setPakeji({...data}))
    return () => setPakeji(_ref);
    // eslint-disable-next-line
  }, [uuid]);

  const createUpdate = () => {
    if (!uuid) {
      delete pakeji.uuid;
      sendPostRequest('packages', {...pakeji},
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    } else {
      pakeji.uuid = uuid;
      sendPatchRequest(`packages/${uuid}`, pakeji,
        (loading) => setStatus({ ...{ loading: loading } }), (data) => {
          setStatus({
            ...{
              error: data.error,
              message: data.message,
            },
          });
        });
    }
  };

  const onValueChange = (value, tag) => setPakeji({ ...pakeji, [tag]: value });

  return (
    <EditPage
      title={uuid ? `Edit Package` : "Add Package"}
      status={status}
      btnLabel={uuid ? "Update Now" : "Add Now"}
      onSave={() => createUpdate()}
      validated={
        pakeji.storeRef &&
        pakeji.description &&
        pakeji.playstore !== null &&
        pakeji.duration &&
        !status.loading
      }
      onFinish={() => {
        setStatus({ ...{ loading: false, message: null, error: false } });
        if (!status.error) {
          setPakeji({ ..._ref });
          navigate(`${AppRoutes.packages}`, true);
        }
      }}
    >
      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          label="Store Reference"
          placeholder="Store package reference code"
          type="text"
          id="storeRef"
          errorText=""
          textField={true}
          disabled={status.loading}
          value={pakeji.storeRef}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={4} xl={12}>
        <EasyTextEdit
          placeholder="Package Description"
          label="Meaningful package description"
          type="text"
          id="description"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={pakeji.description}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={3} xl={12}>
        <EasyTextEdit
          placeholder="Package Duration"
          label="Subscription duration in days"
          type="number"
          id="duration"
          textField={true}
          errorText=""
          disabled={status.loading}
          value={pakeji.duration}
          onChange={onValueChange}
        />
      </Grid>

      <Grid item xs={2} xl={12}>
        <FormControl sx={{ minWidth: 50 }} fullWidth>
          <InputLabel id="store">Store</InputLabel>
          <Select
            value={pakeji.playstore}
            label="Store"
            labelId="store"
            id="playstore"
            onChange={(e) => onValueChange(e.target.value, "playstore")}
          >
            {[
              { name: "PlayStore", value: true },
              { name: "AppleStore", value: false },
            ].map((souces) => {
              return (
                <MenuItem value={souces.value}>
                  &nbsp;&nbsp;&nbsp;{souces.name}
                  <br />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

    </EditPage>
  );
};

export default PackageEdit;