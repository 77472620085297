import {
  getActiveUser,
  navigate,
  guardRoute,
  sendGetRequest,
  formatDate,
  sendDeleteRequest
} from "../utils/utils";
import DataListWithSearch from "./component/@list/list-search";
import { useState, useEffect } from "react";
import AppRoutes from "../routing/routes";

const getTableHead = () => {
  return [
    { id: "createdAt", label: "Created On", alignRight: false },
    { id: "storeRef", label: "Store Code", alignRight: false },
    { id: "description", label: "Description", alignRight: false },
    { id: "duration", label: "Duration", alignRight: false },
    { id: "playstore", label: "Store", alignRight: false }];
};

const startEdit = (uuid) => navigate(`${AppRoutes.packages}/${AppRoutes.package}${uuid ? `?uuid=${uuid}` : ''}`, true);


const PackageList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('packages', uuid))).then(response => {
          executeFetch();
        })
      }
    },
    {
      icon: "edit",
      value: "edit",
      label: "Edit",
      onClick: (_value, selected) => startEdit(selected),
    }
  ];
  const filterOptions = [];

  const executeFetch = () => {
    sendGetRequest('packages?metadata=true', (loading) => onLoading(loading),
      (data) => setDataList(data));
  };


  useEffect(
    () => executeFetch(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedUser.email]
  );


  return (
    <div>
      <DataListWithSearch
        sectionTitle="Subscription Packages"
        createNewLabel={loggedUser.active ? "Add Package" : null}
        data={dataList}
        primaryKey="uuid"
        filterColumn={"storeRef"}
        emptyIcon="card_membership"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => startEdit()}
        headers={getTableHead()}
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.createdAt), width: 200 },
            {
              value: `${data.storeRef}`,
            },
            { value: data.description },
            { value: `${data.duration} Days` },
            { value: `${data.playstore}` === 'true' ? 'Playstore' : 'AppleStore' },
          ];
          return {
            exclude: [],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter((option) => option.value !== "edit")}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />

    </div>
  );
};

export default PackageList;