import Icon from "@mui/material/Icon";
import { logout } from "../utils/utils";
import AppRoutes from "./routes";
import { styled } from "@mui/material/styles";

const ListIconStyle = styled(Icon)({
  fontSize: "1.7rem !important",
});

const getIcon = (name) => <ListIconStyle children={name} />;

const navConfig = [
  {
    title: "Contents",
    path: `${AppRoutes.dashboard}/${AppRoutes.contents}`,
    icon: getIcon("dashboard"),
  },
  {
    title: "Channels",
    path: `${AppRoutes.dashboard}/${AppRoutes.channels}`,
    icon: getIcon("playlist_play"),
  },
  {
    title: "Upload Tasks",
    path: `${AppRoutes.dashboard}/${AppRoutes.tasks}`,
    icon: getIcon("task_alt"),
  },
  {
    title: "Users",
    path: `${AppRoutes.dashboard}/${AppRoutes.users}`,
    icon: getIcon("group"),
  },
  {
    title: "Supported Languages",
    path: `${AppRoutes.dashboard}/${AppRoutes.languages}`,
    icon: getIcon("language"),
  },
  {
    title: "Packages",
    path: `${AppRoutes.dashboard}/${AppRoutes.packages}`,
    icon: getIcon("card_membership"),
  },
  {
    title: "Payments",
    path: `${AppRoutes.dashboard}/${AppRoutes.payments}`,
    icon: getIcon("receipt_long"),
  },
  {
    title: "CI/CD",
    icon: getIcon("account_tree"),
     path: `${AppRoutes.dashboard}/${AppRoutes.cicd}`,
    //action: () => window.open(`https://ci.ubongo.org`, "_blank"),
  },
  {
    title: "Reset Password",
    path: `${AppRoutes.dashboard}/${AppRoutes.password}`,
    icon: getIcon("lock_reset"),
  },
  {
    title: "Logout",
    path: ``,
    icon: getIcon("logout"),
    action: () => logout(),
  },
];

export default navConfig.filter(nav => nav !== null);
