import PropTypes from "prop-types";
import { forwardRef } from "react";
import { coreStyles } from "../../theme/style";
import Status from "./status";
import Page from "./page";
import {
  Grid,
  Typography,
  Icon,
  Stack,
  Box,
  Button,
  Card,
  LinearProgress,
  IconButton,
} from "@mui/material";

const EditPage = forwardRef(
  (
    {
      children,
      title = "",
      showBtn = true,
      removeTopPadding = false,
      onBack,
      btnIcon = "check",
      validated = false,
      backgroundColor,
      onSave,
      btnLabel = "Save Now",
      withTitle = false,
      spacing = 6,
      onFinish,
      py = 0.75,
      px = 2.5,
      status = {},
      ...other
    },
    ref
  ) => {
    const appStyle = coreStyles();
    return (
      <>
        <Page>
          <Box component="form" noValidate sx={{ m: `${py}% ${px}%`}}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Stack direction="row">
                {onBack && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => onBack()}
                    sx={{
                      marginRight: `16px !important;`,
                    }}
                  >
                    <Icon children={"arrow_back"} />
                  </IconButton>
                )}
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    margin: onBack ? `8px 0 0 24px !important;` : ""
                  }}
                >
                  {title}
                </Typography>
              </Stack>

              {showBtn && btnLabel && (
                <Button
                  className={appStyle.actionButon}
                  variant="contained"
                  disabled={!validated}
                  onClick={() => onSave()}
                  startIcon={
                    <Icon
                      children={btnIcon}
                      className={appStyle.actionButonIcon}
                    />
                  }
                >
                  {btnLabel}
                </Button>
              )}
            </Stack>
            {status.loading && <LinearProgress />}
            <Card sx={{ p: removeTopPadding ? "1% 2%" : withTitle ? "2% 4%" : "4% 5% 5% 5%", backgroundColor: backgroundColor }}>
              {status.message && (
                <Status
                  error={status.error}
                  message={status.message}
                  onClose={() => onFinish()}
                />
              )}
              <Grid
                container
                spacing={spacing}
                justifyContent="start"
                sx={{ mt: removeTopPadding ? 0 : status && status.message ? 0 : 2 }}
                ref={ref}
                {...other}
              >
                {children}
              </Grid>
            </Card>
          </Box>
        </Page>
      </>
    );
  }
);

EditPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  validated: PropTypes.bool.isRequired,
  onBack: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
  showBtn: PropTypes.bool,
  btnIcon: PropTypes.string,
  withTitle: PropTypes.bool,
  px: PropTypes.number,
  spacing: PropTypes.number,
  py: PropTypes.number
};

export default EditPage;
