import AppRoutes from "../routing/routes";
import {
  guardRoute,
  watchLiveData,
  getStatus,
  sendGetRequest,
  formatDate,
  navigate,
  getActiveUser,
  sendDeleteRequest
} from "../utils/utils";
import { useState, useEffect } from "react";
import DataListWithSearch from "./component/@list/list-search";

const getTableHead = () => { 
  return [
    { id: "createdAt", label: "Purchased on",  align:'left'},
    { id: "transactionId", label: "Transaction ID",  align:'left'},
    { id: "from", label: "Purchased By",  align:'left'},
    { id: "package", label: "Package",  align:'left'},
    { id: "price", label: "Price", align:'left' },
    { id: "platform", label: "Platform", align:'left' },
    { id: "status", label: "Status", align:'left' }
  ];
};

const PaymentList = () => {
  guardRoute();
  const loggedUser = getActiveUser();
  const [dataList, setDataList] = useState([]);
  const [loading, onLoading] = useState(false);

  const userOptions = [
    {
      icon: "delete",
      value: "delete",
      label: "Delete",
      onClick: (value, selected) => {
        onLoading(true);
        Promise.all(selected.map(uuid => sendDeleteRequest('paywall', uuid))).then(response => {
          executeFetch();
        })
      }
    },
  ];
  const filterOptions = [];

  const startEdit = (uuid) => navigate(`${AppRoutes.payments}/${AppRoutes.payment}${uuid ? `?uuid=${uuid}` : ''}`, true);

  const executeFetch = (refresh = false) => {
    sendGetRequest('paywall?metadata=true', (loading) => onLoading(refresh ? false: loading), (data) => 
    setDataList(data.map(_data => {
      return {
        uuid: _data.uuid,
        purchasedOn: _data.purchasedOn,
        email: _data.user.email,
        name: `${_data.user.firstName} ${_data.user.lastName}`,
        price: _data.price,
        duration: _data.package.duration,
        expired: _data.expired,
        transactionId: _data.transactionId ? _data.transactionId: "N/A",
        platform: _data.package.playstore ? "Android":"iOS",
        package: _data.package.storeRef
      }
    })));
  };

  useEffect(() => {
    executeFetch();
    watchLiveData("paywall", (data) => executeFetch(true));
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <DataListWithSearch
        sectionTitle="Payment history"
        createNewLabel={loggedUser.active ? "Clear payment" : null}
        data={dataList}
        primaryKey="uuid"
        filterColumn={"email"}
        emptyIcon="receipt_long"
        avatarKey="uuid"
        loading={loading}
        onCreateNewItem={() => startEdit()}
        headers={getTableHead()}
        rowData={(data) => {
          const _entries = [
            { value: formatDate(data.purchasedOn, "MMM DD, YYYY hh:mm A"), width: 200 },
            { value: data.transactionId},
            { value: `${data.email} (${data.name})`,},
            { value: data.package},
            { value: `$${data.price}` },
            { value: data.platform },
            { ...getStatus(data.expired ? "Expired":"Active")}
          ];
          return {
            exclude: [],
            entries: _entries,
          };
        }}
        selectionOptions={userOptions.filter((option) => option.value !== "edit")}
        filterOptions={filterOptions}
        optionItems={userOptions}
      />

    </div>
  );
};

export default PaymentList;
